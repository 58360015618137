import React, { ReactElement, useEffect } from "react";
import { css } from "@emotion/react";
import { Layout } from "../components/Layout/Layout";
 
import { Reviews } from "../modules/Reviews/Reviews";
import { REVIEWS_DATA } from "../data/reviews"; 
import { Intro } from "../modules/Intro/Intro";
import { FeatureParagraphMarkLong,} from "../styles/styles";
 
const ReviewsPage = React.memo(
  (): ReactElement => (
 
    <Layout css={css`overflow-y: hidden; }`}>

      <Reviews
        data={REVIEWS_DATA}
        title={
          <>
           We love our customers <br/> + they love us!
          </>
        }
        subtitle={
          <>
           What are folks saying about SketchWow? Here's a collection of reviews and testimonials  taken from <strong css={FeatureParagraphMarkLong}>actual comments</strong> posted by SketchWow customers on our socials...
          </>
        }
      />


      <Intro

        topTitle={
          <>
          With SketchWow in your corner... go create something great!
          </>
        }
        title={
          <>
           Time To Ditch The Dull Diagrams & Flowcharts...
          </>
        }
        withSignUp={false}
        withFooter={false}
      />


 

    </Layout>
  )
);

export default ReviewsPage;
